.status-container {
  position: absolute;
  top: 180%;
  right: -90px;
  background: #fff;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: max(280px, 16vw);
  padding: 20px;z-index: 10;
  p {
    font-family: "nunitoreg";
    text-align: start !important;
  }
  .status-top {
    margin-bottom: 20px;
    h4 {
      font-family: "nunitobold";
      font-size: max(12px, 0.7vw);
    }
    .remove-status {
      gap: 5px;
      button {
        .rem-icon {
          content: url("../../../../../assets/Dashboard/Overview/Nav/close-outline.png");
        }
      }
      p {
        color: #a1a2a4;
        font-size: 12px !important;
      }
    }
  }
  .status-input {
    width: 100%;
    height: max(35px, 2vw);
    justify-content: flex-start;
    border-radius: 50px;
    border: 1px solid #e9e9e9;
    padding: 0 10px;
    gap: 10px;
    margin-bottom: 20px;
    .smile2 {
      width: max(25px, 1.5vw);
      height: max(25px, 1.5vw);
      border: 1px solid #e9e9e9;
      border-radius: 50px;
      .smile2-icon {
        width: 60%;
        content: url("../../../../../assets/Dashboard/Overview/Nav/smile2.png");
      }
    }
    input {
      display: flex;
      flex: 1;
      border: none;
      outline: none;
      color: #a0a7ad;
      font-size: max(11px, 0.65vw);
      &::placeholder {
        color: #a0a7ad;
        font-size: max(11px, 0.65vw);
      }
    }
  }

  .stat-check {
    align-items: flex-start;
    gap: 10px;
    input {
      width: max(16px, 0.9vw);
      height: max(16px, 0.9vw);
    }
    .stat-info {
      align-items: flex-start;
      justify-content: flex-start;
      gap: 5px;
      h2 {
        font-size: 13px;
      }
      p {
        text-align: unset;
        align-items: flex-start;
        color: #78797a;
        font-size: 12px;
        width: 100%;
      }
    }
  }

  .setstatus {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .ss {
      width: max(120px, 6.5vw);
      height: max(30px, 2vw);
      background-color: #4280eb;
      color: #fff;
      border-radius: 50px;
      padding-left: max(10px, 1vw);
      p {
        font-size: max(10px, 0.6vw);
      }
      .ss-btn {
        width: max(30px, 2vw);
        height: max(30px, 2vw);
        background: #47a3f9;
        border-radius: 100%;
        svg {
          width: 60%;
          height: 60%;
          color: #fff;
        }
      }
    }
  }
}


body.dark-mode{
  .status-container{
    background: #27313B !important;
    box-shadow: unset;
    h4{
      color: #fff;
    }
    .status-input{
      .smile2{
        .smile2-icon{
          .smile2-icon{
            filter: invert(100%) !important;
          }
        }
      }
    }
  }
}