.npr2 {
  .npr-top {
    justify-content: space-between !important;
  }
  .npr2-content {
    padding: max(5px, 1vw) max(20px, 1.5vw);
    #npr2-tx {
      font-size: max(13px, 0.75vw);
      margin-bottom: 10px;
    }
    .npr2-items {
      align-items: flex-start;
      flex-wrap: wrap;
      align-items: stretch;
      gap: max(10px, 1vw) max(10px, 1.3vw);
      @media (max-width: 1300px) {
        .npr2-item {
          width: 23% !important;
        }
      }
      .npr2-item {
        background-color: #fff;
        position: relative;
        border-radius: 5px;
        width: max(80px, 7.4vw);
        padding: max(10px, 1vw) max(10px, 1vw) max(5px, 0.8vw);
        gap: max(10px, 0.9vw);
        text-align: center;
        transition: 0.2s;
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          p {
            svg {
              display: none;
            }
          }
        }

        p {
          position: relative;
          gap: 5px;
          font-size: max(10px, 0.65vw);
          line-height: 1.1;
          svg {
            color: #2a2b2b;
            width: 18px;
            min-width: 18px;
            height: 18px;
            min-height: 18px;
            font-size: 18px !important;
          }
        }
        .npr-arr {
          position: absolute;
          bottom: 0;
          z-index: -1;
          font-size: 30px;
          color: var(--main-color);
          transition: 0.2s;
        }
      }
    }
  }

  .npr-bottom {
    #pr-next {
      width: 115px !important;
    }
  }
}
