.dashboard-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: max(7px, 0.7vw) max(25px, 2vw);
  background: #fff;
  border-bottom: 1px solid #ececec;
  gap: 20px;
  @media (max-width: 400px) {
    gap: 10px !important;
    padding: max(13px, 0.7vw) max(25px, 2vw);
  }

  p {
    font-family: "nunitoreg";
  }

  button {
    background: none;
    outline: none;
    border: none;
  }

  .left-nav {
    gap: 10px;
    .menu-button {
      svg {
        font-size: max(20px, 1.5vw);
      }
    }

    a {
      .logo {
        content: url("../../../assets/Dashboard/Overview/Nav/logo.png");
      }
      .logo-2 {
        content: url("../../../assets/Dashboard/Overview/Nav/logo2.svg");
      }

      @media (max-width: 450px) {
        .logo {
          width: 130px;
        }
      }
    }
  }

  .inner-nav2 {
    gap: 10px;
    color: #bcbfc2;
    @media (max-width: 850px) {
      display: none;
    }

    a {
      color: #657a8e;
      font-size: max(10px, 0.65vw);

      svg {
        color: #bcbfc2;
        font-size: 15px;
      }
    }

    .select-container {
      position: relative;
      width: 140px;
      height: max(max(30px, 1.8vw));
      border-radius: 25px;
      border: 1px solid #e1e5ea;
      gap: 5px;
      padding: 0 10px;

      @media (max-width: 850px) {
        display: none;
      }

      .personal-icon {
        content: url("../../../assets/Dashboard/Overview/Nav/profile.png");
        width: max(15px, 1vw);
        height: max(15px, 1vw);
      }

      span {
        font-size: 12px;
        flex: 1;
        color: #000;
      }
    }
  }

  .inner-nav3 {
    .search-container {
      position: relative;
      height: max(35px, 2.5vw);
      border-radius: 50px;
      border: 1px solid #e1e5ea;
      width: max(200px, 22.5vw);
      padding: 0 max(10px, 1vw);
      @media (max-width: 670px) {
        background: #fff !important;
        z-index: 999 !important;
        top: 60px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        width: 90% !important;
        left: 5% !important;
      }
      .search-items {
        background: #fff;
        border-radius: 5px;
        position: absolute;
        top: 120%;
        left: 0;
        width: 100%;
        border: 1px solid #ddd;
        // max-height: 300px;
        // overflow-y: auto !important;
        // &::-webkit-scrollbar {
        //   width: 5px;
        //   border-radius: 10px;
        // }
        // &::-webkit-scrollbar-track {
        //   background: #fff;
        //   border-radius: 10px;
        // }
        // &::-webkit-scrollbar-thumb {
        //   background: #dbdbdb;
        //   border-radius: 10px;
        // }
        .src-top {
          padding: 20px;
          p {
            font-size: max(12px, 0.75vw);
            font-family: "nunitosemi";
          }
          .max {
            width: 26px;
            height: 26px;
            border-radius: 5px;
            border: 1px solid #d6d6d6;
            background: #f9f9f9;
            cursor: pointer;
            svg {
              width: 60%;
            }
          }
        }

        .react-tabs__tab--selected {
          background: #4280eb;
          border-radius: 16px;
          color: #fff;
        }

        .src-list {
          justify-content: flex-start;
          gap: 5px 20px;
          padding: 0 20px 20px 20px;
          border-bottom: 1px solid #d1d1d1;
          flex-wrap: wrap;
          .src-tab {
            font-size: max(10px, 0.6vw);
            cursor: pointer;
            padding: max(5px, 0.5vw) max(8px, 0.75vw);
          }
        }
        .sri-inner {
          width: 100%;
          padding: 10px 20px 20px 20px;
          display: flex;
          flex-direction: column;
          gap: 5px;
          .search-item {
            padding: 5px 0;
            display: flex;
            align-items: center;
            gap: max(10px, 1vw);
            .src-iconn {
              border: 1px solid #c2c2c2;
              border-radius: 5px;
              width: max(30px, 2.35vw);
              height: max(30px, 2.35vw);
              .src-icon {
                width: 90%;
                height: 90%;
                background-size: cover !important;
                background-position: center !important;
                background-repeat: no-repeat !important;
              }
            }
            .src-res {
              display: flex;
              flex-direction: column;
              gap: 3px;
              .src-lk {
                color: #484848;
                font-size: max(8px, 0.6vw);
              }
              a {
                color: #000000;
                font-size: max(12px, 0.8vw);
              }
            }
          }
        }

        .src-btm {
          gap: 5px;
          padding: 10px 0;
          border-top: 1px solid #d1d1d1;
          background: #f9f9f9;
          svg {
            color: #4e4e4e;
          }
          a {
            font-size: max(10px, 0.65vw);
            color: #000000;
            span {
              font-weight: bold;
            }
          }
        }
      }

      @media (max-width: 670px) {
        display: none;
      }

      span {
        svg {
          font-size: max(15px, 1vw);
          color: #7795a6;
        }
      }

      input {
        height: 100%;
        display: flex;
        flex: 1;
        border: none;
        outline: none;
        padding-left: 10px;
        color: #7795a6;
        font-size: max(10px, 0.7vw);

        &::placeholder {
          color: #7795a6;
          font-size: max(10px, 0.7vw);
        }
      }

      button {
        svg {
          font-size: max(18px, 1.3vw);
        }
      }
    }

    .nav-srch {
      display: none;
      margin-left: 10px;
    }
  }

  .right-nav {
    gap: max(10px, 1.5vw);
    @media (max-width: 670px) {
      #sep-src {
        display: flex !important;
      }
    }
    #sep-src {
      display: none;
    }
    // flex-wrap: wrap;
    .upgrade {
      color: #eea21d;
      gap: 5px;

      @media (max-width: 1230px) {
        display: none;
      }

      svg {
        font-size: 18px;
      }

      font-size: max(10px, 0.65vw);
    }

    .ov-new {
      width: max(90px, 5.75vw);
      height: max(30px, 2vw);
      background-color: #4280eb;
      color: #fff;
      border-radius: 50px;
      position: relative;
      cursor: pointer;

      @media (max-width: 400px) {
        width: max(70px, 5.75vw);
        height: max(25px, 2vw) !important;

        .plus {
          margin: 0 0px 0 5px !important;
        }

        p {
          font-size: max(10px, 0.65vw) !important;
        }

        .ov-arr-down {
          width: max(25px, 2vw) !important;
          height: max(25px, 2vw) !important;
        }
      }

      svg {
        color: #fff;
      }

      .plus {
        margin: 0 5px 0 10px;
      }

      p {
        font-size: max(10px, 0.65vw);
      }

      .ov-arr-down {
        width: max(30px, 2vw);
        height: max(30px, 2vw);
        background: #6097f5;
        border-radius: 100%;

        svg {
          width: 60%;
          height: 60%;
        }
      }
    }

    .ov-message,
    .ov-ring,
    .ov-menu {
      position: relative;
      @media (max-width: 1230px) {
        display: none;
      }

      svg {
        width: max(18px, 1.2vw) !important;
        height: max(18px, 1.2vw) !important;
        color: #9099a2;
      }
    }

    .ov-user {
      gap: 5px;
      position: relative;
      cursor: pointer;

      p {
        font-size: max(10px, 0.75vw);
      }

      .user-icon {
        content: url("../../../assets/Dashboard/Overview/Nav/user.png");
        width: max(25px, 1.15vw);
        height: max(25px, 1.15vw);
      }
    }
  }
}

body.dark-mode {
  .dashboard-nav {
    background-color: #1D2731 !important;
    border-bottom: 1px solid #46505a !important;
    a,
    svg,
    span {
      color: #fff !important;
    }
    .search-items {
      background: #27313b !important;
      .src-top {
        .max {
          background: #8a8a8a !important;
          border: none !important;
          svg {
            color: #fff !important;
          }
        }
      }

      .src-btm {
        background: #27313b !important;
      }
    }
    .select-container {
      background: #27313b !important;
    }
    input {
      background: transparent !important;
      color: #fff;
      &::placeholder {
        color: #fff;
      }
    }

    .left-nav {
      .menu-button {
        svg {
          color: #fff;
        }
      }
      a {
        filter: brightness(10) !important;
      }
    }

    .right-nav {
      .upgrade {
        color: #eea21d !important;
        svg {
          color: #eea21d !important;
        }
      }
    }
  }
}
