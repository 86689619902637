.pr-preview-page {
  height: 100vh;
  padding: max(50px, 4vw) 10% 0 10%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  font-family: "nunitoreg";
  overflow: hidden;
  .pr-view-log {
    content: url("../../../assets/Dashboard/ScreenView/logo.svg");
  }

  #title {
    font-size: 16px;
    color: #303030;
  }

  h1 {
    font-size: max(25px, 3.8vw);
    line-height: 1;
  }

  .prPrev-count {
    gap: 10px;
    .prprv-icon {
      content: url("../../../assets/Dashboard/ScreenView/sc.svg");
    }
    p {
      font-size: 14px;
      color: #a0a7ad;
    }
  }

  .view-prj {
    width: max(140px, 8.3vw);
    height: max(40px, 3vw);
    border-radius: 30px;
    background-color: var(--main-color);
    margin-top: 10px;
    margin-bottom: 50px;
    color: #fff;
    font-size: max(14px, 0.8vw);
  }

  .view-image {
    width: 100%;
    position: relative;
    @media (max-width: 1200px) {
      width: 130% !important;
    }
    .view-light {
      position: absolute;
      width: 100%;
      height: 120%;
      background-color: #f1f4f8;
      border-radius: max(10px, 3vw);
      z-index: -1;
      bottom: -40%;
      @media (max-width: 1200px) {
        width: 130% !important;
        border-radius: unset;
        bottom: -50%;
      }
    }
    .view-thumb {
      position: absolute;
      width: 47%;
      height: 85%;
      margin-bottom: 2%;
      border-radius: 10px;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      @media (max-width: 700px) {
        width: 62%;
        height: 85%;
        border-radius: 5px;
      }
    }
    .view-img-inner {
      content: url("../../../assets/Dashboard/ScreenView/macbook\ black.png");
      width: 58.9%;
      @media (max-width: 700px) {
        width: 80%;
      }
    }
  }
}
