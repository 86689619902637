.dashboard-page {
  padding: 0;
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  .ov-main1 {
      align-items: stretch !important;
    position: relative;
    // width: 100%;
  }
  @media (max-width: 1360px) {
    margin-top: 55px !important;
  }

  .main-dashboard-container {
    width: 100%;
    .selected {
      .tab-iconn {
        background: rgba($color: #0084ff, $alpha: 0.3) !important;
        transition: 0.3s;
        .tab-icon {
          filter: brightness(10);
        }
      }
    }
    .tbs-container {
      align-items: flex-start;
      height: 100%;
      .right-side {
        flex: 1;
      }
      .dash-sidebar {
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-between !important;
        height: 100% !important;
        transition: width 0.2s !important;
        -webkit-transition: width 0.2s !important;
      }
    }
  }
}

body.dark-mode {
  .dashboard-page {
    background: #1d2731 !important;
    color: #fff;
  }
}
