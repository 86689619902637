.new-container {
  width: max(200px, 11vw);
  background: #fff;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: absolute;
  top: 115%;
  right: 0;
  .new-inner {
    position: relative;
    padding: 15px 20px;
    align-items: flex-start;
    gap: max(10px, 1vw);
    svg {
      position: absolute;
      top: -19px;
      right: 2px;
      font-size: 30px;
    }
    .flex {
      width: 100%;
      justify-content: flex-start;
      gap: max(15px, 1vw);
      color: #000;
      cursor: pointer;
      p {
        font-size: max(12px, 0.7vw);
      }
      .ni1 {
        content: url("../../../../assets/Dashboard/Overview/Nav/New/icon1.png");
      }
      .ni2 {
        content: url("../../../../assets/Dashboard/Overview/Nav/New/icon2.png");
      }
      .ni3 {
        content: url("../../../../assets/Dashboard/Overview/Nav/New/icon3.png");
      }
      .ni4 {
        content: url("../../../../assets/Dashboard/Overview/Nav/New/icon4.png");
      }
      .ni5 {
        content: url("../../../../assets/Dashboard/Overview/Nav/New/icon5.png");
      }
      .ni6 {
        content: url("../../../../assets/Dashboard/Overview/Nav/New/icon6.png");
      }
    }
  }
}


body.dark-mode{
  .new-container{
    background: #27313B !important;
    box-shadow: unset;
   .new-inner{
    svg{
      color: #27313B !important;
    }
    .flex{
      .new-icon{
        filter: brightness(10);
      }
      p{
        color: #fff;
      }
    }
   }
  }
}