.rez {
  max-width: 100% !important;
  display: flex;
  justify-content: center;

  img {
    max-width: 100% !important;
  }
}
.new-comm {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: var(--main-color);
  position: absolute;
  // z-index: 10 ;
  box-shadow: rgba(57, 109, 255, 0.5) 0px 0px 0px 6px;
  cursor: pointer;
  @media (max-width: 500px) {
    width: 20px;
    height: 20px;
    #c-nr {
      font-size: 7px !important;
    }
  }

  .new-comm-inner {
    position: relative;
    width: 100%;
    height: 100%;
    #c-nr {
      position: absolute;
      font-size: 10px;
      // font-weight: bold !important;
    }

    .msnn {
      width: 50%;
      height: 50%;
      background: url("../../../assets/Dashboard/Screens/screenView/comment.png");
      background-size: cover;
    }

    .comment-section {
      position: absolute;
      z-index: 15 !important;
      // left: -2px;
      top: 50px;
      background-color: white;
      border-radius: 15px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      width: max(300px, 21vw);
      @media (max-width: 500px) {
        width: max(200px, 21vw);
        top: 30px;
        border-radius: 10px !important;
        .comm-bottom {
          .scbr-bg-clrs {
            gap: 5px !important;

            .scbr-bg-clr {
              position: relative;
              width: 13px !important;
              height: 13px !important;
              .outl {
                top: -2px !important;
                left: -2px !important;
                right: -2px !important;
                bottom: -2px !important;
              }
            }
          }

          button {
            width: max(50px, 5.5vw) !important;
            height: max(20px, 2vw) !important;
            font-size: max(8px, 0.8vw) !important;
            border-radius: 5px !important;
          }
        }
        input {
          height: 30px !important;
        }
        #upp {
          top: -7px !important;
          left: 6px !important;
        }
        p {
          font-size: 10px !important;
        }
        svg {
          font-size: 12px !important;
        }
      }
      .comm-sec-inner {
        padding: max(15px, 1.2vw);
        width: 100%;
        position: relative;
        .comm-inf {
          margin-bottom: 20px;
          p {
            color: var(--main-color);
            font-size: max(11px, 0.65vw);
          }
        }

        #upp {
          position: absolute;
          font-size: 32px;
          top: -18px;
          // left: 3px;
          color: #fff;
        }

        .comm-top {
          margin-bottom: 15px;
          align-items: flex-start;

          svg {
            font-size: 13px;
            color: #5a5353;
            cursor: pointer;
          }
        }

        .comm-type {
          border: 1px solid #c0c0c0;
          border-radius: 20px;
          width: max(140px, 8vw);
          height: max(25px, 1.8vw);
          gap: 5px;
          padding: 0 10px;
          cursor: pointer;

          p {
            flex: 1;
            font-size: max(10px, 0.6vw);
            margin-top: 1px;
          }

          #unlock {
            width: max(12px, 0.8vw);
            height: max(12px, 0.8vw);
            color: #777c82;
          }

          svg {
            font-size: 16px;
          }
        }

        .add-comm {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          margin-top: 10px;
          .adc-user {
            width: max(25px, 1.7vw);
            height: max(25px, 1.7vw);
            content: url("../../../assets/Dashboard/Screens/screenView/user.png");
          }

          .add-comm-area {
            border: 1px solid #b8b8b8;
            border-radius: 8px;
            padding: 8px;
            flex: 1;
            input {
              width: 100%;
              height: 40px;
              border: none;
              outline: none;
              background: transparent;
              font-size: max(11px, 0.7vw);
              color: #768492;
              font-family: "nunitoreg";

              &::placeholder {
                padding-left: 10px;
                font-size: max(11px, 0.7vw);
                color: #768492;
                font-family: "nunitoreg";
              }

              margin-bottom: 10px;
            }

            .adc-tools {
              width: 100%;
              justify-content: flex-end;
              gap: 10px;

              svg {
                color: #6b6363;
                cursor: pointer;
                font-size: 16px;
              }

              #attach {
                transform: rotate(40deg);
              }
            }
          }

          margin-bottom: 20px;
        }

        .comm-bottom {
          gap: 20px;
          flex-wrap: wrap;

          .scbr-bg-clrs {
            gap: 10px;

            .scbr-bg-clr {
              position: relative;
              width: 16px;
              height: 16px;
              border-radius: 100px;
              cursor: pointer;

              .outl {
                position: absolute;
                top: -4px;
                left: -4px;
                right: -4px;
                bottom: -4px;
                border-radius: 100%;
              }
            }

            .c1 {
              background-color: #4280eb;
            }

            .c2 {
              background-color: #e3244b;
            }

            .c3 {
              background-color: #ffb341;
            }
          }

          button {
            width: max(70px, 5.5vw);
            height: max(30px, 2vw);
            border-radius: 8px;
            background: #4280eb;
            color: #fff;
            border: none;
            outline: none;
            font-size: max(11px, 0.8vw);
            font-family: "nunitosemi";
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}

.comments-showed {
  gap: 20px;
  #child {
    width: 100%;
  }
}

.comment {
  background-color: white;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
  gap: 10px;

  .cm-user {
    width: max(25px, 1.7vw);
    height: max(25px, 1.7vw);
    content: url("../../../assets/Dashboard/Screens/screenView/user.png");
  }

  .cm-infos {
    width: 100%;
    .edit-cmm {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      bottom: max(60px, 4.2vw) !important;
      right: max(15px, 1.2vw);
      width: 78.4%;
      border-radius: 10px;
      z-index: 10 !important;
      height: max(95px, 4.8vw);
      background-color: #fff;
      gap: 5px;
      input {
        border: 1px solid #ddd;
        flex: 1;
        padding-left: 5px;
        border-radius: 8px;
        background-color: #fff;
        width: 100%;
      }
      .edt-btns {
        gap: 10px;
        justify-content: flex-end !important;
        gap: 10px;
        width: 100% !important;
        button {
          width: 50px;
          border: none;
          background: var(--main-color);
          color: #fff;
          padding: 5px;
          border-radius: 3px;
        }
        .edt-cnc {
          background: none;
          color: #000;
        }
      }
    }
    .cm-infos-top {
      width: 100%;
      gap: 10px;

      h4 {
        font-size: max(12px, 0.75vw);
      }

      .point {
        width: 6px;
        height: 6px;
        background-color: #7e7e7e;
        border-radius: 100%;
      }

      p {
        flex: 1;
        color: #747474;
        font-size: max(11px, 0.65vw);
      }

      svg {
        color: #90a2b4;
        cursor: pointer;
      }
    }

    #ctm {
      font-size: max(13px, 0.75vw);
      margin-top: 10px;
    }
  }
}

img:hover {
  cursor: crosshair;
}
