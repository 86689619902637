.ov-page {
  flex: 1;
  padding: 30px;
  width: 100% !important;
  .ov-top {
    margin-bottom: 30px;
    width: 100%;
    .ov-topp-inner {
      width: 100%;
      @media (max-width: 1130px) {
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
        .ov-left {
          flex-wrap: wrap;
          gap: 10px;
        }
        .ov-right {
          flex-wrap: wrap;
          gap: 10px;
        }
      }
    }
    .ov-left {
      gap: 20px;
      p {
        font-size: max(14px, 0.9vw);
      }
      a {
        border: 1px solid #4280eb;
        border-radius: 25px;
        padding: max(10px, 0.75vw) max(10px, 1.25vw);
        font-size: max(12px, 0.7vw);
        color: #4280eb;
        &:hover {
          background-color: #4280eb;
          color: #fff;
          transition: 0.3s;
        }
      }
    }

    .ov-right {
      gap: 15px;
      svg {
        color: #395772;
      }
      #filter {
        font-size: 15px;
      }
      .ov-team {
        white-space: nowrap;
        cursor: pointer;
        position: relative;
        height: max(30px, 2vw);
        border: 1px solid #bebebe;
        border-radius: 25px;
        padding: 0 10px 0 10px;
        width: max(180px, 11.5vw);
        #user {
          margin-right: 10px;
        }
        svg {
          font-size: 15px;
        }
        p {
          color: #6b7074;
          font-size: max(10px, 0.65vw);
          margin-right: 5px;
          white-space: nowrap;
        }
        #p-sel {
          flex: 1;
          white-space: nowrap;
        }
      }

      .ov-filter {
        position: relative;
        width: max(110px, 7.2vw);
        height: max(30px, 2vw);
        border: 1px solid #bebebe;
        border-radius: 25px;
        padding: 0 10px 0 10px;
        #filterr {
          font-size: 18px;
          margin-right: 10px;
        }
        p {
          flex: 1;
          font-size: max(10px, 0.65vw);
        }
      }
      .ov-menu {
        width: max(80px, 4.5vw);
        height: max(30px, 2vw);
        padding: 3px 5px;
        gap: 5px;
        border: 1px solid #aeaeae;
        border-radius: 9px;
        background-color: #fcfcfc;
        svg {
          color: #98a2ab;
        }
        .ov-grid,
        .ov-list {
          height: 100%;
          width: 40%;
          border: none;
          border-radius: 5px;
          background: transparent;
          &:hover,
          &:active,
          &:focus {
            background-color: #fff;
            box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
            svg {
              color: #4280eb;
            }
          }
        }
      }
    }
  }

  .ov-sld {
    width: 100%;
  }
  .ov-main {
    align-items: flex-start;
    gap: 30px;
    @media (max-width: 1300px) {
      flex-direction: column;
      .ov-main-left {
        width: 100% !important;
      }
      .ov-main-right {
        width: 100% !important;
      }
    }
    .ov-main-left {
      width: 60%;
    }
    .ov-main-right {
      width: 40%;
    }
  }
  .ov-main2 {
    width: 100%;
    @media (max-width: 1300px) {
      .ov-m2 {
        flex-direction: column;
      }
    }
    .ov-m2 {
      align-items: flex-start;
      gap: 30px;
    }
  }
}

.ov-page {
  .ov-top {
    .ov-topp-inner {
      .ov-right {
        .ov-team {
          .css-b62m3t-container {
            width: 100%;
            border-radius: 50px;
          }
          .css-qc6sy-singleValue {
            color: #000;
            display: flex !important;
            align-items: center !important;
            font-size: max(10px, 0.65vw);
          }
          .css-1w9j89e-menu {
            font-size: max(10px, 0.65vw);
            display: flex !important;
            align-items: center !important;
          }

          .css-1s2u09g-control {
            background: transparent;
          }

          .css-1pahdxg-control {
            background-color: transparent !important;
          }

          .css-1okebmr-indicatorSeparator {
            background-color: transparent !important;
          }

          .css-1pahdxg-control,
          .css-1s2u09g-control {
            box-shadow: none !important;
            border-color: transparent !important;
            border-radius: 6px;
          }
          .css-pqhxpt-control {
            background: transparent;
            font-size: max(10px, 0.65vw);
            height: 20px !important;
            flex: 1;
            width: 100%;
            border: none;
            outline: none;
            .css-1hb7zxy-IndicatorsContainer {
              .css-1u9des2-indicatorSeparator {
                display: none;
                .css-1xc3v61-indicatorContainer {
                  border: 1px solid red;
                }
              }
            }
          }
        }
      }
    }
  }
}


body.dark-mode{
  .ov-top{
    svg{
      color: #fff !important;
    }
    .ov-left{
      a{
        border-color: #61B3FF !important;
        color: #61B3FF;
      }
    }

    .ov-right{

      .ov-menu{
        background: #2F3943;
        border-color: #707070;
        .ov-grid, .ov-list{
          &:hover,
          &:active,
          &:focus {
            background-color: #555f69;
            box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
            svg {
              color: #4280eb !important;
            }
          }
        }
      }
      .ov-team, .ov-filter{
        background: #2F3943 !important;
        border-color: #707070 !important;
        p{
          color: #fff !important;
        }
      }
    }
  }
}