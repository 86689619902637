.drop2 {
  position: absolute;
  top: 95%;
  background: #fff;
  z-index: 100;
  right: 0;
  border-radius: 8px;
  width: max(150px, 10.5vw);
  padding: 20px 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  .dr2-items {
    align-items: flex-start;
    p {
      padding: 10px 20px;
      font-size: max(10px, 0.6vw);
      text-align: left;
      width: 100%;
      transition: 0.2s;
      &:hover {
        background-color: var(--main-color);
        color: #fff;
      }
    }
  }
}
