.guides-container {
  margin: 30px 0 20px 0;
  width: 100%;
  border: 1px solid #e4e4e4;
  box-shadow: rgba(149, 157, 165, 0.05) 0px 8px 24px;
  border-radius: 5px;
  padding: max(20px, 2vw) max(20px, 1.5vw) 20px max(20px, 1.5vw);
  display: flex;
  flex-direction: column;
  gap: 20px;
  .guides-top {
    p {
      font-size: max(14px, 0.9vw);
    }
    a {
      gap: 10px;
      color: #4280eb;
      font-size: max(10px, 0.65vw);
      svg {
        font-size: 13px;
      }
    }
  }

  .gd-items {
    align-items: stretch;
    @media (max-width: 1150px) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px 20px;
      .gd-item {
        width: 45% !important;
      }
    }
    @media (max-width: 900px) {
      .gd-item {
        width: 100% !important;
        justify-content: flex-start !important;
      }
    }
    .gd-item {
      position: relative;
      align-items: flex-start;
      gap: max(5px, 0.75vw);
      color: #000;
      width: 30%;
      padding: 15px 20px 15px 20px;
      border-radius: 12px;
      background-color: #fff;

      &:nth-child(3) {
        svg {
          font-size: max(16px, 1.1vw) !important;
        }
      }
      &:hover {
        box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
        transition: 0.3s;
        .gd-inner {
          left: -7px;
          transition: 0.3s;
        }
        .gd-in-con {
          svg {
            color: #4280eb;
          }
        }
      }
      .gd-inner {
        position: absolute;
        width: 100%;
        height: 95%;
        top: 2.5%;
        left: 0px;
        background-color: #4280eb;
        z-index: -1;
        border-radius: 12px;
      }
      .gd-in-con {
        gap: 15px;
        width: 100%;
        justify-content: space-between;
        .gd-content {
          span {
            font-size: max(13px, 0.75vw);
            font-family: "nunitosemi";
          }
          p {
            font-size: max(10px, 0.65vw);
            width: 80%;
            margin-top: 5px;
            display: flex;
            flex: 1;
            @media (max-width: 1300px) {
              width: 100% !important;
            }
          }
        }
        svg {
          font-size: max(16px, 1.5vw);
          color: transparent;
        }
      }
    }
  }
}


body.dark-mode{
  .guides-container{
    border-color: #707070;
    box-shadow: unset;
    .gd-items{
      .guides-top{
        a{
          color: var(--dark-main) !important;
        }
      }
      .gd-item{
        background: #3A434B;
        color: #fff !important;
        &:hover{
          box-shadow: unset;
        }
      }
    }
  }
}