.user-menu {
  width: max(200px, 13vw);
  position: absolute;
  top: 130%;
  right: -20%;
  background: #fff;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  .inner-user {
    width: 100%;
    height: 100%;
    position: relative;
    .arr-up {
      position: absolute;
      color: #fff;
      font-size: 30px;
      top: -18px;
      right: 10px;
      z-index: -1;
    }
    .user-info {
      border-bottom: 1px solid #e4e4e4;
      padding: 20px 15px 10px 15px;
      align-items: flex-start;
      gap: 10px;
      .user-img {
        width: max(30px, 2.05vw);
        height: max(30px, 2.05vw);
        content: url("../../../../assets/Dashboard/Overview/Nav/Image.png");
      }
      .user-content {
        align-items: flex-start;
        gap: 5px;
        .user-name {
          gap: 5px;
          p {
            font-size: max(10px, 0.7vw);
          }
          .smilee {
            position: relative;
            .smile {
              width: max(17px, 1vw);
              height: max(17px, 1vw);
              content: url("../../../../assets/Dashboard/Overview/Nav/smile.png");
            }
          }
          .free-plan {
            background-color: #c1e1ff;
            width: max(70px, 4.2vw);
            height: max(18px, 1.2vw);
            border-radius: 7px;
            gap: 4px;
            .fp-icon {
              content: url("../../../../assets/Dashboard/Overview/Nav/diamond-half.png");
            }
            p {
              font-size: max(8px, 0.55vw);
            }
          }
        }
        .user-email {
          gap: 5px;
          svg {
            color: #90a2b4;
            font-size: 15px;
          }
          p {
            color: #78797a;
            font-size: max(10px, 0.7vw);
          }
        }
        .user-stat {
          gap: 10px;
          .act-btn {
            width: 9px;
            height: 9px;
            border-radius: 100%;
            background-color: #50cf6a;
          }
          p {
            color: #78797a;
            font-size: max(10px, 0.7vw);
          }
        }
      }
    }

    .user-options {
      align-items: flex-start;
      padding: 15px;
      border-bottom: 1px solid #e4e4e4;
      gap: max(10px, 1vw);
      .uinfo-item {
        justify-content: flex-start;
        width: 100%;
        gap: max(15px, 1.5vw);
        color: #000;
        &:hover {
          p {
            color: #0084ff;
            transition: 0.3s;
          }
        }
        p {
          font-size: max(12px, 0.7vw);
        }

        .ui-icon{
          width: max(16px, 1.2vw);
        }
      }
    }

    .ui-auth {
      padding: 15px;
      justify-content: flex-start;
      gap: max(15px, 1.5vw);
      &:hover {
        p {
          color: #0084ff;
          transition: 0.3s;
        }
      }
      .auth-icon {
        width: max(16px, 1.2vw);
        content: url("../../../../assets/Dashboard/Overview/Nav/login.png");
      }
      p {
        font-size: max(12px, 0.7vw);
      }
    }
  }
}


body.dark-mode{
  .user-menu{
    background: #27313B !important;
    box-shadow: unset;
    .arr-up{
      color: #27313B !important;
    }
    p, span, a{
      color: #fff !important;
    }

    .ui-icon{
      filter: brightness(10);
    }
    .smilee{
      .smile{
        filter: invert(100%);
      }
    }

    .free-plan{
      background: #5e5f61 !important;

    }
  }
}