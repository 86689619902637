.screen-full {
  position: relative;
  overflow-x: hidden;
  height: 100vh;
  align-items: start;
  width: 100%;
  .spf-wrap {
    .sc-prv-full {
      width: 100% !important;
    }
  }
  .sc-b {
    width: 116px;
    height: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #898a89;
    position: fixed;
    border: none;
    border-radius: 100%;
    top: 45%;

    svg {
      color: #fff;
      width: 40%;
      height: 40%;
    }
  }
  .sc-prev {
    left: -58px;
    justify-content: flex-end;
    padding-right: 10px;
    @media (max-width: 600px) {
      width: 80px;
      height: 80px;
      left: -50px;
      padding-right: 0px;
    }
  }
  .sc-next {
    right: -58px;
    justify-content: flex-start;
    padding-left: 10px;
    @media (max-width: 600px) {
      width: 80px;
      height: 80px;
      right: -50px;
      padding-left: 0px;
    }
  }
  .sc-bb {
    position: fixed;
    width: max(40px, 2.5vw);
    height: max(40px, 2.5vw);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #90a2b4;
    border-radius: 100%;
    background-color: #fff;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
      background-color: var(--main-color);
      border-color: var(--main-color);
      svg {
        color: #fff;
      }
    }
    svg {
      width: 50%;
      height: 50%;
      color: #71787f;
    }
  }

  .sc-prv-more {
    bottom: 8%;
    left: 20px;
  }
  .sc-prv-comm {
    bottom: 8%;
    left: 75px;
  }

  .sc-prev-logo2 {
    padding: 10px 15px;
    position: fixed;
    bottom: 8%;
    right: 40px;
    background: rgba($color: #ffffff, $alpha: 0.5);
    border-radius: 50px;
    border: 1px solid #90a2b4;

    .spl2 {
      content: url("../../../assets/Dashboard/ScreenView/logo2.png");
    }
  }
}
