.ps-slider {
  width: 100%;
  .noPrj {
    width: 100%;
    padding-top: 10%;
    flex: 1;
    gap: 20px;
    text-align: center;

    .noprj-icon {
      content: url("../../../../../assets/Dashboard/Overview/AllProjects/noproj.svg");
    }

    h4 {
      font-size: max(16px, 0.9vw);
      font-family: "nunitosemi";
    }

    p {
      font-size: 13px;
    }

    .noprj-btn {
      background-color: #0084ff;
      border: none;
      border-radius: 25px;
      padding: max(10px, 0.75vw) max(15px, 1.5vw);
      color: #fff;
      font-size: max(12px, 0.7vw);
    }
  }
  .prc-slider {
    position: relative;
    .rec-arrow,
    .rec-arrow-left,
    .rec-arrow-right {
      position: absolute;
      border: 1px solid #bebebe !important;
      box-shadow: none !important;
      background: #fff !important;
      width: 40px !important;
      height: 40px !important;
      min-width: 40px !important;
      min-height: 40px !important;
      max-width: 40px !important;
      max-height: 40px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px !important;
      color: #59738d !important;
      z-index: 100;
      &:hover,
      &:active,
      &:focus {
        background: #0e69e7 !important;
        color: #fff !important;
        border: 1px solid #0e69e7 !important;
      }
    }
    .rec-dot {
      display: none;
    }
    .rec-arrow-left {
      left: 0;
    }
    .rec-arrow-right {
      right: 0;
    }
    .prj-item {
      box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
      width: 95%;
      height: max(300px, 20vw);
      border-radius: 10px;
      padding: 5px;
      margin: 20px 0;
      .prj-inner {
        width: 100%;
        height: 100%;

        .prj-img {
          position: relative;
          border-radius: 6px;
          width: 100%;
          height: 83%;
          background-size: 90% !important;
          background-repeat: no-repeat !important;
          background-position: center !important;
          &:hover {
            .prj-hover {
              display: flex;
            }
          }
          .prj-hover {
            border-radius: 6px;
            position: absolute;
            display: none;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0%;
            background: rgba($color: #111a30, $alpha: 0.7);
            border-radius: 10px;
            padding: 20px;
            justify-content: space-between;
            align-items: center;
            .prj-top {
              width: 100%;
              .prj-autors {
                .prj-aut {
                  margin-left: -5px;
                  background-color: unset !important;
                  .prj-aimg1 {
                    width: max(18px, 1.5vw);
                    height: max(18px, 1.5vw);
                    border-radius: 100%;
                  }
                }
                .prj-aut3 {
                  width: 29.36px;
                  height: 29.36px;
                  border-radius: 50px;
                  padding: 1px;
                  margin-left: -10px;
                  .prj-aimg3 {
                    width: 100%;
                    height: 100%;
                    border-radius: 50px;
                    background: #414858;
                    color: #fff;
                    font-size: 11px;
                  }
                }
              }

              button {
                background: #46444c;
                border: none;
                outline: none;
                width: 28px;
                height: 26px;
                border-radius: 4px;
                svg {
                  width: 80%;
                  height: 80%;
                  color: #fff;
                }
              }
            }

            a {
              width: max(80px, 5.6vw);
              height: max(30px, 2.2vw);
              background-color: #396dff;
              color: #fff;
              border-radius: 50px;
              font-size: max(12px, 0.7vw);
            }

            .prj-btm {
              width: 100%;
              justify-content: flex-start;
              gap: 10px;
              color: #fff;
              svg {
                font-size: 15px;
              }
              p {
                font-size: max(11px, 0.65vw);
              }
            }
          }
          .prj-imgg {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            background-position: center !important;
            border-radius: 6px;
          }
          margin-bottom: 10px;
        }
        .prj-content {
          .prj-info {
            padding-left: 10px;
            p {
              font-size: max(12px, 0.75vw);
              font-family: "nunitosemi";
            }
            span {
              font-size: max(10px, 0.6vw);
              color: #8b8b8b;
            }
          }

          .prj-sc {
            align-items: center;
            .sc-icon {
              content: url("../../../../../assets/Dashboard/Overview/Ovpage/screen.png") !important;
            }
            p {
              color: #8b8b8b;
              font-size: max(8px, 0.5vw);
            }
          }
        }
      }
    }
  }
}


body.dark-mode{
  .ps-slider{
    .prc-slider{
      .rec-arrow{
        border-color: #707070 !important;
        background: #37404A !important;
        color: #fff !important;
      }
      .prj-item{
        box-shadow: unset;
        border: 1px solid #707070;
        background: #2A343E;
      }
    }
  }
}