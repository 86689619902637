.new-project-box {
  position: fixed;
  top: 10%;
  left: 35%;
  z-index: 200;
  background-color: #fff;
  width: max(350px, 37vw);
  color: #000;
  border-radius: max(10px, 0.8vw);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 1300px) {
    width: max(350px, 55vw);
    left: 27%;
  }

  @media (max-width: 800px) {
    width: max(350px, 80vw);
    left: 10%;
  }

  @media (max-width: 550px) {
    width: 90%;
    left: 5%;
  }

  .npr-top {
    border-bottom: 1px solid #d0d0d0;
    gap: 20px;
    justify-content: flex-start;
    padding: 20px max(20px, 1.5vw);

    p {
      font-family: "nunitosemi";
      font-size: max(16px, 1vw) !important;
      cursor: pointer;
    }

    #npr2 {
      font-size: max(13px, 0.75vw) !important;
      color: #4280eb;
      flex: 1;
    }

    svg {
      color: #585858 !important;
      font-size: max(16px, 1vw);
    }
  }

  .npr-name {
    padding: max(5px, 1vw) max(20px, 1.5vw);

    p {
      font-family: "nunitosemi";
      font-size: max(13px, 0.75vw) !important;
      margin-bottom: 5px;
    }

    .npr-name-area {
      border: 1px solid #cecece;
      border-radius: 6px;
      height: max(40px, 2.75vw);
      justify-content: flex-start;
      padding-left: 15px;

      .nprname-icon {
        width: max(14px, 0.85vw);
        margin-right: 10px;
        content: url("../../../../../assets/Dashboard/Overview/Nav/New/edit.png");
      }

      input {
        flex: 1;
        height: 100%;
        border: none;
        outline: none;
        font-size: max(12px, 0.75vw);
        color: #7c858e;
        font-family: "nunitoreg";
        border-radius: 0 6px 6px 0;

        &::placeholder {
          font-size: max(12px, 0.75vw);
          color: #7c858e;
          padding-left: 10px;
        }
      }
    }
  }

  .pr-type {
    padding: max(5px, 1vw) max(20px, 1.5vw);
    gap: 20px;
    align-items: stretch;

    @media (max-width:480px) {
      flex-direction: column;

      .prtype {
        width: 100% !important;
        justify-content: flex-start !important;
      }
    }

    .active {
      border-color: #4280eb !important;
    }

    .prtype {
      cursor: pointer;
      border: 1px solid #cecece;
      border-radius: 6px;
      padding: max(10px, 1vw);
      width: 50%;
      gap: max(10px, 1vw);
      transition: 0.2s;

      &:hover,
      &:active,
      &:focus {
        border-color: #4280eb !important;
      }

      .prtype-icon {
        width: max(30px, 2.1vw);
        height: max(30px, 2.1vw);
      }

      .prt-icon1 {
        content: url("../../../../../assets/Dashboard/Overview/Nav/New/private.png");
      }

      .prt-icon2 {
        content: url("../../../../../assets/Dashboard/Overview/Nav/New/public.png");
      }

      .prt-content {
        h4 {
          font-family: "nunitobold";
          font-size: max(13px, 0.75vw);
        }

        p {
          color: #7c858e;
          line-height: 1.7;
        }
      }
    }
  }

  .npr-details {
    padding: max(5px, 1vw) max(20px, 1.5vw);
    gap: 20px;

    @media (max-width: 1520px) {
      flex-direction: column;

      .pr-colors {
        width: 100% !important;
      }

      .pr-icons {
        width: 100% !important;
      }
    }

    @media (max-width: 1300px) {
      flex-direction: unset;

      .pr-colors {
        width: 50% !important;
      }

      .pr-icons {
        width: 50% !important;
      }
    }

    @media (max-width: 1100px) {
      flex-direction: column;

      .pr-colors {
        width: 100% !important;
      }

      .pr-icons {
        width: 100% !important;
      }
    }

    .pr-colors {
      width: 50%;

      p {
        font-family: "nunitosemi";
        font-size: max(13px, 0.75vw) !important;
      }

      .colors {
        gap: 10px;
        justify-content: flex-start;
        margin-top: 5px;

        .pr-color {
          position: relative;
          width: 14px;
          height: 14px;
          border-radius: 50px;

          .inner-pr-color {
            position: absolute;
            top: -4px;
            left: -4px;
            right: -4px;
            bottom: -4px;
            border-radius: 100%;
            transition: 0.2s;
          }
        }

        svg {
          color: #a8afb7 !important;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }

    .pr-icons {
      width: 50%;
      gap: 10px;

      p {
        font-family: "nunitosemi";
        font-size: max(13px, 0.75vw) !important;
      }

      .pri-icons {
        justify-content: flex-start;
        margin-top: 5px;
        gap: 10px;
      }

      svg {
        color: #a8afb7 !important;
        width: 20px;
        min-width: 20px;
        height: 20px;
        max-height: 20px;
        cursor: pointer;
      }
    }
  }

  .pr-description {
    padding: max(5px, 1vw) max(20px, 1.5vw);

    p {
      font-family: "nunitosemi";
      font-size: max(13px, 0.75vw) !important;
      margin-bottom: 5px;

      span {
        font-family: "nunitoreg";
        color: #6e6b6b;
      }
    }

    textarea {
      border: 1px solid #cecece;
      border-radius: 6px;
      width: 100%;
      height: max(80px, 6.65vw);
      max-height: max(80px, 6.65vw);
      outline: none;
      padding: 10px 0 0 10px;
      font-size: max(12px, 0.75vw);

      &::placeholder {
        font-size: max(12px, 0.75vw);
      }
    }
  }

  .npr-bottom {
    padding: max(5px, 1vw) max(20px, 1.5vw);
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 10px;

    button {
      font-size: 13px;
    }

    #pr-next {
      background-color: #4280eb;
      width: max(80px, 5.6vw);
      height: max(30px, 2vw);
      border-radius: 6px;
      color: #fff;

    }
  }
}