.dash-sidebar.open {
  width: 320px;
  height: 100% !important;
}
.dash-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #e2e2e2;
  border-left: unset;
  border-top: unset;
  transition: width 0.2s ease;
  flex: 0 0 auto !important;

  @media (max-width: 700px) {
    position: fixed;
    top: 51px;
    left: 0px;
    background-color: #fff;
    z-index: 1000;
    overflow-y: auto !important;
    padding-bottom: 10%;
    border-right: 1px solid #e2e2e2 !important;

    .mobile-sidebar::-webkit-scrollbar {
      width: 7.5px;
    }

    .mobile-sidebar::-webkit-scrollbar-track {
      background: #e2e2e2;
      border-radius: 1px;
    }

    .mobile-sidebar::-webkit-scrollbar-thumb {
      background-color: #474c55;
      border-radius: 1px;
    }

    @-webkit-keyframes mobile-nav-scroll {
      0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
      }

      100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
      }
    }

    @keyframes mobile-nav-scroll {
      0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
      }

      100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
      }
    }
  }

  .inner-dside {
    position: relative;
    width: 100%;
    .close-side {
      button {
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 50px;
        border: 1px solid #cecece;
        background-color: #fff;
        right: -13px;
        top: 35%;
        @media (max-width: 700px) {
          right: 5px;
        }
        svg {
          width: 100%;
          height: 100%;
          color: #cecece;
        }
      }
    }

    .menu1 {
      .s-tb-list {
        gap: 10px;
        .active-link {
          .tab-iconn {
            background: #5090ff !important;
            .tab-icon {
              filter: brightness(170%) !important;
            }
          }
          p {
            color: var(--main-color) ;
          }
        }
        .main-tab {
          transition: 0.3s;
          justify-content: flex-start;
          color: #000;
          cursor: pointer;
          width: 100% !important;
          .main-tab2 {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 10px;
            .expanded {
              transform: rotate(180deg);
            }
          }
          .arrow-icon {
            margin-right: 20px;
            color: #77818a;
            font-size: 18px;
            transition: 0.3s;
          }
          &:nth-child(5) {
            .arrow-icon {
              display: none;
            }
          }
          &:nth-child(7) {
            .arrow-icon {
              display: none;
            }
          }
          &:nth-child(8) {
            .arrow-icon {
              display: none;
            }
          }
          &:nth-child(9) {
            .arrow-icon {
              display: none;
            }
          }

          &:hover,
          &:active,
          &:focus {
            .tab-iconn {
              background: #5090ff !important;

              .tab-icon {
                filter: brightness(170%) !important;
              }
            }
          }

          p {
            font-size: max(14px, 0.8vw);
            flex: 1;
          }

          .tab-iconn {
            width: max(50px, 3.6vw);
            max-width: max(50px, 3.6vw);
            min-width: max(50px, 3.6vw);
            height: max(35px, 2.3vw);
            background: transparent;
            border-radius: 0 23px 23px 0;
            justify-content: flex-end;
            padding: 0 max(10px, 0.75vw) 0 10px;
          }
        }
      }
    }
  }

  .btm-side {
    margin-top: 100px;
    align-items: center;

    .upgrade {
      position: relative;
      width: 80%;
      padding: max(30px, 3vw) 20px 20px 20px;
      background: url("../../../assets/Dashboard/Overview/Ovpage/Guides/Group\ 11072.webp");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      color: #fff;
      align-items: center;
      gap: 15px;
      border-radius: 20px;
      .c1 {
        position: absolute;
        content: url("../../../assets/Dashboard/Overview/Ovpage/Guides/c1.png");
        top: -5%;
        left: 12%;
      }

      .c2 {
        position: absolute;
        content: url("../../../assets/Dashboard/Overview/Ovpage/Guides/c2.png");
        top: -8%;
        left: 30%;
      }

      .c3 {
        position: absolute;
        content: url("../../../assets/Dashboard/Overview/Ovpage/Guides/c3.png");
        top: 6%;
        right: -3%;
      }

      .c4 {
        position: absolute;
        content: url("../../../assets/Dashboard/Overview/Ovpage/Guides/c4.png");
        top: 50%;
        left: -3%;
      }

      .c5 {
        position: absolute;
        content: url("../../../assets/Dashboard/Overview/Ovpage/Guides/c6.png");
        bottom: 2%;
        right: 4%;
      }

      .c6 {
        position: absolute;
        content: url("../../../assets/Dashboard/Overview/Ovpage/Guides/c5.png");
        bottom: 7%;
        right: 13%;
      }

      h4 {
        font-size: 14px;
      }

      button {
        background-color: #ffe15b;
        border: none;
        height: 40px;
        gap: 10px;
        font-size: 12px;
        border-radius: 7px;
        width: 100%;
      }

      .b2-items {
        width: 100%;
        gap: 15px;
        margin-top: 10px;

        .b2-item {
          justify-content: flex-start;
          gap: 10px;

          p {
            font-size: 13px;
          }
        }
      }

      a {
        height: 55px;
        gap: 15px;
        border: 1px solid #fff;
        border-radius: 30px;
        width: 75%;
        background: rgba($color: #fff, $alpha: 0.1);
        backdrop-filter: blur(10px);
        color: #fff;
        margin-top: 20px;
      }
    }

    .side-links {
      gap: 20px;
      margin-top: 100px;
      width: 100%;
      padding: 0 20px;
      justify-content: flex-start;

      a {
        font-size: 13px;
        color: #000;
        display: flex;
        align-items: center;

        svg {
          font-size: 15px;
        }
      }
    }

    .side-infos {
      width: 100%;
      padding: 0 20px;
      justify-content: flex-start;
      gap: 3px;
      margin-top: 10px;

      p {
        font-size: 13.5px;
      }

      #p-1 {
        color: #6c7c86;

        a {
          color: #0084ff;
        }
      }
    }

    .side-switch {
      margin: 10px 0 20px 0;
    }
  }
}

@-webkit-keyframes mobile-nav-scroll {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@keyframes mobile-nav-scroll {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

body.dark-mode {
  .dash-sidebar {
    .menu1 {
      .s-tb-list {
        .active-link {
          p {
            color: #fff !important;
          }
        }
      }
    }
    .changer {
      .ch-light {
        svg {
          color: #fff !important;
        }
      }
      .mode-switcher-container {
        .mode-switch {
          background: #434d57 !important;
        }
      }
    }
    border-color: #46505a !important;
    p {
      color: #fff;
    }
    .clsbtn {
      background: #37404a !important;
      border: none;
    }
    .btm-side {
      .side-links {
        a,
        p {
          color: #fff !important;
        }
      }
      .upgrade {
        background: url("../../../assets/shared/nav/upgrade-bg.webp") !important;
        background-size: cover !important;
        .c1,
        .c2,
        .c3,
        .c4,
        .c5,
        .c6 {
          filter: invert(60%) !important;
        }
      }
    }
    .tab-icon {
      filter: brightness(10) !important;
    }
    .menu2-container {
      border-top: 1px solid #46505a !important;
    }
  }
}
