.ntm-container {
  position: fixed;
  top: 10%;
  left: 35%;
  z-index: 200;
  background-color: #fff;
  width: max(350px, 37vw);
  color: #000;
  border-radius: max(10px, 0.8vw);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width:1050px) {
    width: max(350px, 50vw);
    left: 25%;

  }

  @media (max-width:750px) {
    width: 70%;
    left: 15%;

  }

  @media (max-width:550px) {
    width: 90%;
    left: 5%;
    padding-bottom: 10px !important;

    .tm-btm {
      flex-wrap: wrap;
      gap: 10px;
    }
  }

  .npr-top {
    border-bottom: 1px solid #d0d0d0;
    gap: 20px;
    justify-content: flex-start;
    padding: 20px max(20px, 1.5vw);

    p {
      font-family: "nunitosemi";
      font-size: max(16px, 1vw) !important;
      cursor: pointer;
      flex: 1;
    }

    svg {
      color: #585858 !important;
      font-size: max(16px, 1vw);
    }
  }

  .npr-name {
    padding: max(5px, 1vw) max(20px, 1.5vw);

    p {
      font-family: "nunitosemi";
      font-size: max(13px, 0.75vw) !important;
      margin-bottom: 5px;
    }

    .npr-name-area {
      border: 1px solid #cecece;
      border-radius: 6px;
      height: max(40px, 2.75vw);
      justify-content: flex-start;
      padding-left: 15px;

      .nprname-icon {
        width: max(14px, 0.85vw);
        margin-right: 10px;
        content: url("../../../../../assets/Dashboard/Overview/Nav/New/edit.png");
      }

      input {
        flex: 1;
        height: 100%;
        border: none;
        outline: none;
        font-size: max(12px, 0.75vw);
        color: #7c858e;
        font-family: "nunitoreg";
        border-radius: 0 6px 6px 0;

        &::placeholder {
          font-size: max(12px, 0.75vw);
          color: #7c858e;
          padding-left: 10px;
        }
      }
    }
  }

  .tm-drp {
    width: 100%;
    padding: max(5px, 1vw) max(20px, 1.5vw);

    .dp-inner {
      position: relative;
      margin-top: 10px;
      border: 1px solid #cecece;
      border-radius: 6px;
      width: 100%;
      height: max(40px, 2.85vw);
      padding: 0 max(5px, 0.5vw);

      .dpprj-icon {
        width: max(14px, 0.9vw);
        margin-right: 10px;
        content: url("../../../../../assets/Dashboard/Overview/Nav/New/users-line.svg");
      }

      .dpprt-icon {
        width: max(18px, 1.2vw);
        margin-right: 10px;
        content: url("../../../../../assets/Dashboard/Overview/Nav/New/web.svg");
      }

      p {
        font-size: max(12px, 0.7vw);
        flex: 1;
        margin: 0;
        padding: 0;
      }

      svg {
        font-size: 18px;
        color: #5b5b5b;
      }
    }
  }

  .pr-description {
    padding: max(5px, 1vw) max(20px, 1.5vw);

    .tm-area {
      border: 1px solid #cecece;
      border-radius: 6px;
      width: 100%;
      height: max(80px, 6.65vw);
      display: flex;
      align-items: flex-start;
      padding: 10px 0 0 10px;
      gap: 10px;

      svg {
        width: 14px;
        min-width: 14px;
        font-size: 14px;
        color: #90a2b4;
      }
    }

    p {
      font-family: "nunitosemi";
      font-size: max(13px, 0.75vw) !important;
      margin-bottom: 5px;

      span {
        font-family: "nunitoreg";
        color: #6e6b6b;
      }
    }

    textarea {
      border: none;
      outline: none;
      width: 100%;
      height: 100%;
      max-height: max(80px, 6.65vw);
      outline: none;
      font-size: max(12px, 0.75vw);

      &::placeholder {
        font-size: max(12px, 0.75vw);
      }
    }
  }

  .tm-btm {
    padding: max(5px, 1vw) max(20px, 1.5vw);

    .exx {
      color: #4280eb;
      gap: 10px;

      svg {
        color: #4280eb;
        font-size: max(13px, 0.8vw);
      }

      p {
        font-size: max(12px, 0.7vw);
      }
    }

    .tm-btns {
      gap: 20px;

      button {
        font-size: 13px;
      }

      #next {
        background-color: #4280eb;
        width: max(80px, 5.6vw);
        height: max(30px, 2vw);
        border-radius: 6px;
        color: #fff;
      }
    }
  }

  .tm2-btm {
    padding: max(5px, 1vw) 0;

    .exx {
      color: #4280eb;
      gap: 10px;

      svg {
        color: #4280eb;
        font-size: max(14px, 1vw);
      }

      p {
        font-size: max(12px, 0.7vw);
      }
    }

    .tm-btns {
      gap: 20px;

      button {
        font-size: 13px;
      }

      #create {
        background-color: #4280eb;
        width: max(100px, 5.6vw);
        height: max(30px, 2vw);
        border-radius: 6px;
        color: #fff;
      }
    }
  }

  .tm-iner {
    padding: 20px max(20px, 1.5vw);

    .tm-tx {
      width: 100%;

      p {
        font-size: max(12px, 0.7vw);
      }
    }

    .tm-emails {
      width: 100%;

      .tm-titles {
        margin-top: max(10px, 1vw);

        p {
          font-size: max(13px, 0.75vw);
          font-family: "nunitosemi";
        }

        span {
          color: #4280eb;
          font-family: "nunitoreg";
          font-size: max(12px, 0.75vw);
        }
      }

      .tm-area-em {
        border: 1px solid red;
        display: flex;
        align-items: flex-start;
        padding: 10px 0 0 10px;
        border: 1px solid #d0d0d0;
        border-radius: 6px;
        margin-top: 7px;
        height: max(120px, 7.8vw);

        .tmar-icon {
          content: url("../../../../../assets/Dashboard/Overview/Nav/New/edit.png");
          width: max(14px, 0.85vw);
          margin-right: 20px;
        }

        .tm-txa {
          flex: 1;
          height: 100%;
          border: none;
          outline: none;
        }
      }
    }
  }
}