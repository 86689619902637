.changer {
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    font-size: 0.85vw;
    font-family: "poppins Regular";
  }
  .ch-light {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    font-size: 13px;
    svg {
      font-size: 16.5px;
      color: #6C7C86;
    }
  }
  .mode-switcher-container {
    .mode-switch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #cbcbcb;
      width: 39px;
      height: 19px;
      overflow: hidden;
      border-radius: max(20px, 1vw);
      border: 1px solid #e5e5e5;
      background-color: #E6E5E5;
    }
    .mode-switch .iconn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 50px;
      width: 12px !important;
      height: 12px !important;
      &:nth-child(1) {
        margin-left: 4px;
      }
      &:nth-child(2) {
        margin-right: 4px;
      }
    }
    .mode-switch .active {
      background: transparent;
      background-color: #fff;
    }
    .nav .mode-switch {
      display: none;
    }
  }

  .footer .nav-mode-switch {
    display: none;
  }
  .ch-dark {
    display: flex;
    align-items: center;
    gap: 7px;
    svg {
      font-size: 15.5px;
      color: #6C7C86;
    }
  }
}



body.dark-mode {
  .changer {
    .ch-dark {
      font-size: 13px;
      svg {
        color: #fff !important;
      }
    }
    .ch-light {
      svg {
        color: #6b6b6b !important;
      }
    }
  }
}
