.tn-box-items{
    display: flex;
    flex-direction: column;
    .tn-top-item{
        display: flex;
        align-items: start;
        gap: 10px;
        padding: max(10px, 1vw);
        .tntop-user{
            min-width: max(25px, 1.55vw) ;
            min-height: max(25px, 1.55vw) ;
            background-size: cover !important;
            border-radius: 100%;
            background-position: center;
        }

        .tntop-content{
            flex: 1;
            p{
                color: #747474;
                font-size: max(12px, 0.75vw);
                display: flex;
                flex-wrap: wrap;
                gap: 3px;
                a{
                    color: var(--main-color);
                }
                h4{
                    color: #000;

                }
            }
            #tn-cr{
                display: flex;
                color: #747474;
                font-size: 11px;
            }
            .com-place{
                border: 1px solid #e9e9e9;
                padding: 5px;
                display: flex;
                border-radius: 5px;
                background: #f8f8f8;
                margin: 10px 0;
                p{
                    font-size: max(12px, 0.7vw);
                }
            }
            .comm-look{
                width: max(40px, 3.6vw);
            }
        }
    }
}

body.dark-mode{
    .tn-box-items{
        .com-place{
            background: #3c4853 !important;
            border-color: #646464 !important;
        }
    }
}