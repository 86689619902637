.ov-comments-container {
  width: 100%;
  border: 1px solid #e4e4e4;
  box-shadow: rgba(149, 157, 165, 0.05) 0px 8px 24px;
  border-radius: 10px;
  overflow-y: auto !important;
  height: max(400px, 30vw);
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 10px;
  }
  .ov-com-top {
    padding: max(10px, 1vw);
    flex-wrap: wrap;
    .ongtop-left {
      gap: max(5px, 0.5vw);
      p {
        font-size: max(14px, 0.9vw);
        font-family: "nunitosemi";
      }
      .ong-nr {
        border: 1px solid #cccccc;
        border-radius: 23px;
        background-color: #fbfbfb;
        width: max(25px, 1.5vw);
        height: max(20px, 1.25vw);
        font-size: max(8px, 0.65vw);
      }
      a {
        color: #4280eb;
        font-size: max(8px, 0.65vw);
      }
    }
    .ongtop-right {
      gap: 10px;
      svg {
        color: #395772;
        font-size: 15px;
      }
      .ov-from {
        width: max(140px, 8.5vw);
        height: max(30px, 2vw);
        border: 1px solid #bebebe;
        border-radius: 25px;
        padding: 0 10px;
        gap: 5px;
        span {
          color: #a0a7ad;
          font-size: max(10px, 0.65vw);
        }
        #filterr {
          font-size: 18px;
          margin-right: 10px;
        }
        p {
          flex: 1;
          font-size: max(10px, 0.65vw);
        }
      }
      .ov-filter {
        width: max(100px, 7.2vw);
        height: max(30px, 2vw);
        border: 1px solid #bebebe;
        border-radius: 25px;
        padding: 0 10px;
        gap: 5px;
        #filterr {
          font-size: 18px;
          margin-right: 10px;
        }
        p {
          flex: 1;
          font-size: max(10px, 0.65vw);
        }
      }
    }
  }

  .com-comments {
    .c-ico {
      width: max(30px, 2.2vw);
      width: max(30px, 2.2vw);
      margin-right: 10px;
    }
    a {
      color: #4280eb;
      font-size: max(10px, 0.7vw);
      font-family: "nunitosemi";
    }
    .user1 {
      position: relative;
      width: 100%;
      border-top: 1px solid #e3e2e2;
      border-bottom: 1px solid #e3e2e2;
      padding: 20px 20px 10px max(10px, 1.5vw);
      align-items: flex-start;
      justify-content: flex-start;
      .com-ring {
        position: absolute;
        right: 5%;
        width: max(25px, 2vw);
        height: max(25px, 2vw);
        border-radius: 50px;
        background-color: #dfe7ff;
        svg {
          width: 60%;
          height: 60%;
          color: #4280eb;
        }
      }
      .comm-icon {
        content: url("../../../../..//assets/Dashboard/Overview/Ovpage/Comments/user1.png");
      }
    }
    .user2 {
      width: 100%;
      border-bottom: 1px solid #e3e2e2;
      padding: 10px 20px 10px max(10px, 1.5vw);
      align-items: flex-start;
      justify-content: flex-start;
      .comm2-icon {
        content: url("../../../../..//assets/Dashboard/Overview/Ovpage/Comments/user2.png");
      }
    }
    .user3 {
      width: 100%;
      padding: 10px 20px 10px max(10px, 1.5vw);
      align-items: flex-start;
      justify-content: flex-start;
      .comm3-icon {
        content: url("../../../../..//assets/Dashboard/Overview/Ovpage/Comments/user2.png");
      }
      .com-content {
        .com-look {
          justify-content: flex-start;
          gap: 10px;
          margin-top: 10px;
          .in-user1 {
            content: url("../../../../..//assets/Dashboard/Overview/Ovpage/Comments/inner1.png");
          }
          .in-user2 {
            content: url("../../../../..//assets/Dashboard/Overview/Ovpage/Comments/inner2.png");
          }
          .in-user3 {
            content: url("../../../../..//assets/Dashboard/Overview/Ovpage/Comments/inner3.png");
          }
        }
      }
    }
    .com-content {
      gap: 10px;
      width: 80%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .com-title {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 3px;
        font-size: max(10px, 0.7vw);
        span,
        p {
          font-size: max(10px, 0.7vw);
        }
        span {
          font-family: "nunitobold";
        }
        p {
          color: #747474;
        }
      }
      .com-date {
        p {
          color: #747474;
          font-size: max(8px, 0.55vw);
        }
      }
      .com-look {
        .comm-inner {
          .comin-top {
            border-left: 2px solid #dbdbdb;
            padding-left: 10px;
            .comintop-content {
              background-color: #fafafa;
              padding: 8px 10px 8px 10px;
              width: max(180px, 12vw);
              justify-content: flex-start;
              border-radius: 8px;
            }
            .cmt2 {
              width: max(220px, 14.5vw);
            }
          }
          .comm-icon2 {
            width: max(15px, 0.9vw);
            height: max(15px, 0.9vw);
            margin-right: 5px;
            content: url("../../../../..//assets/Dashboard/Overview/Ovpage/Comments/comment.png");
          }
          span {
            font-family: "nunitobold";
            font-size: max(8px, 0.6vw);
            margin: 0 3px 0 10px;
          }
          p {
            font-size: max(8px, 0.6vw);
            line-height: 1;
            flex: 1;
          }
          .rocket {
            margin-left: 10px;
            width: max(14px, 0.8vw);
            height: max(14px, 0.8vw);
            content: url("../../../../..//assets/Dashboard/Overview/Ovpage/Comments/rocket.png");
          }
          .check {
            color: #4ec97b;
            font-size: max(15px, 1vw);
          }
        }
        .comm-img2 {
          width: max(180px, 12vw);
          content: url("../../../../..//assets/Dashboard/Overview/Ovpage/Comments/img1.png");
          margin: 10px 0 0 10px;
        }
      }
    }
  }
}


body.dark-mode{
  .ov-comments-container{
    background: #2A343E;
    border-color: #707070;
    box-shadow: unset;
    &::-webkit-scrollbar-track {
      background: #5e5e5e;
    }
    &::-webkit-scrollbar-thumb {
      background: #949494;
    }
    .ov-com-top{
      .ongtop-right{
        Svg{
          color: #fff !important;
        }
        .ov-filter, .ov-from{
          background: #3D4853;
          border-color: #707070;
        }
      }
    }

    .com-comments{
      .user1, .user2, .user3{
        border-color: #707070 !important;
        p{
          color: #BCBCBC !important;
        }
        .comintop-content{
          background: #3D4853 !important;
          p{
            color: #fff !important;
          }
        }
      }
    }
  }
}
