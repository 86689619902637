.ov-tasks {
    width: 60%;
    border: 1px solid #e4e4e4;
    box-shadow: rgba(149, 157, 165, 0.05) 0px 8px 24px;
    border-radius: 10px;
    margin-top: 30px;
    height: max(300px, 24vw);
@media (max-width:1300px) {
    width: 100%;
}
    .act-top {
      padding: max(10px, 1vw);
      flex-wrap: wrap;
      .act-left {
        gap: max(5px, 1.5vw);
        p {
          font-size: max(14px, 0.9vw);
          font-family: "nunitosemi";
        }
        .ong-nr {
          border: 1px solid #cccccc;
          border-radius: 23px;
          background-color: #fbfbfb;
          width: max(25px, 1.5vw);
          height: max(20px, 1.25vw);
          font-size: max(8px, 0.65vw);
        }
        a {
          color: #4280eb;
          font-size: max(8px, 0.65vw);
        }
      }
      .act-right {
        gap: 10px;
        svg {
          color: #395772;
          font-size: 15px;
        }
        .ov-menu {
          width: max(80px, 4.5vw);
          height: max(30px, 2vw);
          padding: 3px 5px;
          gap: 5px;
          border: 1px solid #aeaeae;
          border-radius: 9px;
          background-color: #fcfcfc;
          svg {
            color: #98a2ab;
          }
          .ov-grid,
          .ov-list {
            height: 100%;
            width: 40%;
            border: none;
            border-radius: 5px;
            background: transparent;
            &:hover,
            &:active,
            &:focus {
              background-color: #fff;
              box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
              svg {
                color: #4280eb;
              }
            }
          }
        }
        .act-filter {
          width: max(130px, 8.5vw);
          height: max(30px, 2vw);
          border: 1px solid #bebebe;
          border-radius: 25px;
          padding: 0 10px;
          gap: 5px;
          #filterr {
            font-size: 18px;
            margin-right: 10px;
          }
          p {
            flex: 1;
            font-size: max(10px, 0.65vw);
          }
        }
      }
    }
  
    .tasks-chart {
        height: 75%;
    }

  }
  

  body.dark-mode{
    .ov-tasks{
      background: #2A343E;
      border-color: #707070;
      box-shadow: unset;
      .act-top{
        .act-right{
          .act-filter{
            background: #3D4853;
            border-color: #707070;
            svg{
              color: #fff;
            }
          }
        }
      }

    }
  }