.direct-drop2 {
    position: absolute;
    top: 110%;
    right: 0%;
    width: 100%;
    border-radius: 10px;
    padding: 20px 0;
    z-index: 100;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    flex-direction: column;
    p {
      cursor: pointer;
      padding: 5px 10px 5px 20px !important;
      padding-left: 5px;
      &:hover {
        background-color: rgb(71, 157, 255);
        color: #fff !important;
      }
    }
  }
  