.uploadmenu {
  padding-left: max(60px, 4vw) !important ;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: max(15px, 1vw);
  a {
    gap: 10px;
    width: 100%;
    p {
      color: #000;
      font-size: max(12px, 0.7vw);
    }
  }
}
