.menu2-container {
  button {
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .m2-top {
    padding: 0 10px 0 max(25px, 2vw);
    margin: 20px 0 20px 0;
    .m2-teams {
      gap: 5px;
      font-size: 15px;
      .tms-icon {
        content: url("../../../assets/Dashboard/Overview/Sidebar/teams.png");
      }
    }
    .tms-tools {
      gap: 10px;
      button {
        svg {
          font-size: 18px;
        }
        .plus {
          color: #4280eb;
        }
        .arr-m2 {
          color: #7c828d;
        }
        .arr-rot {
          transform: rotate(180deg);
          transition: 0.3s;
          color: #7c828d;
        }
      }
    }
  }

  .m2-item {
    color: #000;
    margin-bottom: 10px;
    padding-right: 10px;
    gap: 10px;
    cursor: pointer;
    &:hover{
      .m2-title {
        .m2-iconn {
          background: #d4eaff !important;
          transition: 0.3s;
        }
      }
    }

    .m2-title {
      gap: 10px;
      .m2-iconn {
        width: max(50px, 3.6vw);
        max-width: max(50px, 3.6vw);
        min-width: max(50px, 3.6vw);
        height: max(35px, 2.3vw);
        background: transparent;
        border-radius: 0 23px 23px 0;
        justify-content: flex-end;
        padding: 0 3px 0 10px;
        .m2-icon {
          width: max(28px, 1.6vw);
          height: max(28px, 1.6vw);
        }
      }

      p {
        font-size: 14px;
        font-family: "nunitosemi";
      }
    }

    .m2-tools {
      gap: 10px;
      .dots-menu {
        position: relative;
      }

      button {
        svg {
          font-size: 20px;
          color: #7c828d;
        }
      }
      .m2-pm {
        position: relative;
        width: 51px;
        height: 24px;
        border-radius: 50px;
        border: 1px solid #cccccc;
        background-color: #fbfbfb;
        gap: 5px;
        &:hover {
          .m2-hover {
            display: flex;
          }
        }
        .m2-hover {
          position: absolute;
          top: 115%;
          z-index: 100;
          display: none;
          .m2-inhv {
            position: relative;
            padding: 5px;
            background-color: #6a727b;
            color: #fff;
            border-radius: 3px;
            p {
              font-size: max(8px, 0.55vw);
            }
            svg {
              position: absolute;
              top: -11px;
              left: 30%;
              font-size: 20px;
              color: #6a727b;
            }
          }
        }
        span {
          font-size: 11px;
          font-family: "nunitobold";
          color: #78818b;
        }
        svg {
          color: #78818b;
          font-size: 14px;
        }
      }
    }
  }
}


body.dark-mode{
  .menu2-container{
    .m2-top{
      .m2-teams{
        .tms-icon{
          filter: brightness(10);
        }
        a{
          color: #fff !important;
        }
      }

      button{
        color: #fff;
      }
      svg{
        color: #fff ;
      }
    }

    .m2-item{
      &:hover{
        .m2-title {
          .m2-iconn {
            background: rgba($color: #0084FF, $alpha: 0.4) !important;
            transition: 0.3s;
          }
        }
      }
    }

    .m2-tools{
      .m2-pm{
        background: #27313B;
      }
    }
  }
}

