.ov-activity {
  width: 100%;
  border: 1px solid #e4e4e4;
  box-shadow: rgba(149, 157, 165, 0.05) 0px 8px 24px;
  border-radius: 10px;
  margin-top: 30px;
  .act-top {
    padding: max(10px, 1vw);
    flex-wrap: wrap;
    .act-left {
      gap: max(5px, 0.5vw);
      p {
        font-size: max(14px, 0.9vw);
        font-family: "nunitosemi";
      }
      .ong-nr {
        border: 1px solid #cccccc;
        border-radius: 23px;
        background-color: #fbfbfb;
        width: max(25px, 1.5vw);
        height: max(20px, 1.25vw);
        font-size: max(8px, 0.65vw);
      }
      a {
        color: #4280eb;
        font-size: max(8px, 0.65vw);
      }
    }
    .act-right {
      gap: 10px;
      svg {
        color: #395772;
        font-size: 15px;
      }
      .ov-menu {
        width: max(80px, 4.5vw);
        height: max(30px, 2vw);
        padding: 3px 5px;
        gap: 5px;
        border: 1px solid #aeaeae;
        border-radius: 9px;
        background-color: #fcfcfc;
        svg {
          color: #98a2ab;
        }
        .ov-grid,
        .ov-list {
          height: 100%;
          width: 40%;
          border: none;
          border-radius: 5px;
          background: transparent;
          &:hover,
          &:active,
          &:focus {
            background-color: #fff;
            box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
            svg {
              color: #4280eb;
            }
          }
        }
      }
      .act-filter {
        width: max(130px, 8.5vw);
        height: max(30px, 2vw);
        border: 1px solid #bebebe;
        border-radius: 25px;
        padding: 0 10px;
        gap: 5px;
        #filterr {
          font-size: 18px;
          margin-right: 10px;
        }
        p {
          flex: 1;
          font-size: max(10px, 0.65vw);
        }
      }
    }
  }

  .activity-area {
    width: 100%;
    padding: 0 10px;
    .react-calendar-heatmap text {
      font-size: max(6px, 0.4vw);
      fill: #000000;
    }
    .react-calendar-heatmap {
      width: 100%;
      margin-top: 20px;
    }

    .react-calendar-heatmap .react-calendar-heatmap-small-text {
      font-size: max(8px, 0.6vw);
    }

    .react-calendar-heatmap rect:hover {
      stroke: #0862b5;
      stroke-width: 0.5px;
      cursor: pointer;
    }

    /*
           * Default color scale
           */

    .react-calendar-heatmap .color-empty {
      fill: #efefef;
    }

    .react-calendar-heatmap .color-filled {
      fill: #0862b5;
    }
  }

  .activity-exp {
    margin: 20px 0;
    p {
      font-size: max(8px, 0.6vw);
    }
    gap: 5px;
    .act-color {
      width: max(10px, 0.7vw);
      height: max(10px, 0.7vw);
      border-radius: 3px;
    }
    .col1 {
      background-color: #efefef;
    }
    .col2 {
      background-color: #a1caf1;
    }
    .col3 {
      background-color: #55abfb;
    }
    .col4 {
      background-color: #0084ff;
    }
    .col5 {
      background-color: #0862b5;
    }
  }
}


body.dark-mode{
  .ov-activity{
    background: #2A343E;
    border-color: #707070;
    box-shadow: unset;
    .act-right{
      .ov-menu{
        background: #3D4853;
        border-color: #707070;
        .ov-grid, .ov-list{
          &:hover,
          &:active,
          &:focus {
            background-color: #555f69;
            box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
            svg {
              color: #4280eb !important;
            }
          }
        }
      }

      .act-filter{
        background: #3D4853;
        border-color: #707070;
        svg{
          color: #fff;
        }
      }
    }

   
  .activity-area {

    .react-calendar-heatmap text {
      fill: #fff;
    }
    .react-calendar-heatmap rect:hover {
      stroke: #0084FF;
    }
    .react-calendar-heatmap .color-empty {
      fill: #515A63;
    }

    .react-calendar-heatmap .color-filled {
      fill: #0084FF;
    }
  }

  .activity-exp{
    .col1 {
      background-color: #515A63;
    }
    .col2 {
      background-color: #A1CAF1;
    }
    .col3 {
      background-color: #55ABFB;
    }
    .col4 {
      background-color: #0084FF;
    }
    .col5 {
      background-color: #0862B5;
    }
  }
  }
}