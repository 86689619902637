.ongoing-component {
  width: 100%;
  border: 1px solid #e4e4e4;
  box-shadow: rgba(149, 157, 165, 0.05) 0px 8px 24px;

  border-radius: 5px;
  height: max(375px, 23.3vw);
  overflow-y: auto !important;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 10px;
  }
  .ong-top {
    padding: max(10px, 1vw);
    @media (max-width: 580px) {
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
    }
    .ongtop-left {
      gap: max(5px, 0.5vw);
      p {
        font-size: max(14px, 0.9vw);
        font-family: "nunitosemi";
      }
      .ong-nr {
        border: 1px solid #cccccc;
        border-radius: 23px;
        background-color: #fbfbfb;
        width: max(25px, 1.5vw);
        height: max(20px, 1.25vw);
        font-size: max(8px, 0.65vw);
      }
      a {
        color: #4280eb;
        font-size: max(8px, 0.65vw);
      }
    }
    .ongtop-right {
      gap: 10px;
      svg {
        color: #395772;
        font-size: 15px;
      }
      .ov-filter {
        width: max(100px, 7.2vw);
        height: max(30px, 2vw);
        border: 1px solid #bebebe;
        border-radius: 25px;
        padding: 0 10px;
        gap: 5px;
        #filterr {
          font-size: 18px;
          margin-right: 10px;
        }
        p {
          flex: 1;
          font-size: max(10px, 0.65vw);
        }
      }
    }
  }

  .ongoing-titles {
    padding: 10px max(10px, 2vw);
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    p {
      color: #6b6a6a;
      font-size: max(8px, 0.6vw);
    }
    .ong-name {
      width: 40%;
      justify-content: flex-start;
      gap: 5px;
      .ong-arrow {
        content: url("../../../../../assets/Dashboard/Overview/Ovpage/Arrow.png");
      }
    }
    .ong-people {
      width: 15%;
    }
    .ong-screens {
      width: 15%;
    }
    .ong-sharelink {
      width: 15%;
    }
    .ong-blank {
      width: 15%;
    }
  }
  .ongoing-items {
    .ongoing-item {
      border-bottom: 1px solid #e3e2e2;
      padding: max(10px, 0.8vw) max(10px, 2vw);
      .ong-namee {
        width: 40%;
        justify-content: flex-start;
        gap: 10px;
        .ong-info {
          p {
            font-size: max(10px, 0.7vw);
          }
          span {
            position: relative;
            font-size: max(9px, 0.65vw);
            color: #6a6a6a;
            justify-content: flex-start;
            gap: 5px;
            cursor: pointer;
            &:hover {
              .ong-hover {
                display: flex;
              }
            }
            .verify {
              content: url("../../../../../assets/Dashboard/Overview/Ovpage/Ongoing/check.png");
            }
            .ong-hover {
              position: absolute;
              top: 115%;
              z-index: 100;
              display: none;
              .m2-inhv {
                position: relative;
                padding: 5px;
                background-color: #6a727b;
                color: #fff;
                border-radius: 3px;
                width: 80px;
                p {
                  font-size: max(8px, 0.55vw);
                }
                svg {
                  position: absolute;
                  top: -11px;
                  left: 30%;
                  font-size: 20px;
                  color: #6a727b;
                }
              }
            }
          }
        }
      }
      .ong-pp {
        width: 15%;
        .ong-profile1 {
          width: max(20px, 1.55vw);
          content: url("../../../../../assets/Dashboard//Overview//Ovpage/Ongoing/img1.png");
        }
        .ong-profile2 {
          margin-left: -10px;
          width: max(20px, 1.55vw);
          content: url("../../../../../assets/Dashboard//Overview//Ovpage/Ongoing/img2.png");
        }
        .ong-profile3 {
          margin-left: -10px;
          width: max(20px, 1.55vw);
          content: url("../../../../../assets/Dashboard//Overview//Ovpage/Ongoing/img10.png");
        }
      }
      .ong-screenss {
        width: 15%;
        gap: 5px;
        .ong-sc-icon {
          content: url("../../../../../assets/Dashboard//Overview//Ovpage/screen.png");
        }
        span {
          font-size: max(9px, 0.65vw);
        }
      }
      .ong-sharee {
        width: 15%;
        .ong-sh-icon {
          cursor: pointer;
          content: url("../../../../../assets/Dashboard/Overview/Ovpage/Ongoing/share.png");
        }
      }
      .ong-blankk {
        width: 15%;
        svg {
          color: #b4b0b0;
          font-size: 20px;
          cursor: pointer;

        }
      }
    }
  }
}


body.dark-mode{
  .ongoing-component{
    box-shadow: unset;
    border-color: #707070 !important;
    &::-webkit-scrollbar-track {
      background: #5e5e5e;
    }
    &::-webkit-scrollbar-thumb {
      background: #949494;
    }
    .ong-top{
      background: #2A343E;
      svg{
        color: #fff !important;
      }
      .ongtop-right{
        .ov-filter{
          background: #3D4853 !important;
          border-color: #707070 !important;
        }
      }
      .ong-nr{
        background: #2A343E;
        border-color: #707070 !important;

      }
    }

    .ongoing-titles{
      border-color: #707070 !important;
      background: #242E37 !important;
      p{
        color: #fff;
      }
    }

    .ongoing-items{
      background: #2A343E;
      .ongoing-item{
        border-color: #707070;
        .ong-info{
          p, span{
            color: #fff !important;
          }
        }
      }
    }
  }
}