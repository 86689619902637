.dp-container {
  span {
    font-family: "nunitosemi";
    font-size: max(13px, 0.75vw) !important;
  }

  .npr-top {
    .dpr-arr {
      width: max(25px, 1.5vw);
      height: max(25px, 1.5vw);
      border-radius: 50px;
      border: 1px solid #dfdfdf;

      svg {
        width: 60%;
        color: #c4c4c4 !important;
      }
    }

    p {
      flex: 1;
    }
  }

  .dp-drops {
    padding: 0 max(20px, 1.5vw) max(5px, 1vw) max(20px, 1.5vw);
    gap: 15px;

    .dp-drp {
      width: 50%;

      .dp-inner {
        position: relative;
        margin-top: 10px;
        border: 1px solid #cecece;
        border-radius: 6px;
        width: 100%;
        height: max(40px, 2.85vw);
        padding: 0 max(5px, 0.5vw);

        .dpprj-icon {
          width: max(10px, 0.7vw);
          margin-right: 10px;
          content: url("../../../../../assets/Dashboard/Overview/Nav/New/lock.svg");
        }

        .dpprt-icon {
          width: max(18px, 1.2vw);
          margin-right: 10px;
          content: url("../../../../../assets/Dashboard/Overview/Nav/New/web.svg");
        }

        p {
          font-size: max(12px, 0.7vw);
          flex: 1;
          margin: 0;
          padding: 0;
        }

        svg {
          font-size: 18px;
          color: #5b5b5b;
        }
      }
    }
  }

  .dp-upload {
    padding: max(5px, 1vw) max(20px, 1.5vw);

    .selected-files {
      border: 1px solid #d6d6d6;
      padding: 20px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      gap: 10px;
      .file-item2 {
        justify-content: space-between;
        .prg-cont {
          gap: 7px;
          p {
            font-size: max(11px, 0.7vw);
            font-family: "nunitoreg";
          }
          .fl-share {
            gap: 5px;
            cursor: pointer;
            svg {
              font-size: max(16px, 0.9vw);
              color: #000;
            }
          }
        }
        svg {
          color: #90a2b4;
          font-size: 14px;
        }
        .fi-1 {
          flex: 1;
          gap: 5px;
          justify-content: flex-start;
          #up {
            color: #0084ff;
            font-size: 14px;
          }
          #check {
            font-size: 14px;
            color: #00c300;
          }
          span {
            font-size: max(13px, 0.75vw);
          }
          img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }
          #f-name {
            flex: 1;
            font-size: max(13px, 0.75vw);
            font-family: "nunitosemi";
          }
        }
        .prt2 {
          align-items: center;
          gap: 10px;
          span {
            gap: 10px;
            .f-tm {
              width: 50px;
            }
          }
          .prg {
            width: max(120px, 8vw);
            margin: 0;
            padding: 0;
            margin-bottom: 10px;
            progress {
              width: 100%;
              background: #00c300 !important;
              height: 5px;
              border-radius: 10px !important;
              flex: 1;
            }
          }
        }
      }
    }

    .upload-area {
      border-radius: 6px;
      padding: max(20px, 2vw) 20px;
      margin-top: 10px;

      p {
        font-size: max(10px, 0.6vw);
        text-align: center;
      }

      button {
        width: max(120px, 7vw);
        height: max(40px, 2.5vw);
        border-radius: 7px;
        border: none;
        outline: none;
        color: #fff;
        background-color: var(--main-color);
        gap: 10px;
        margin-top: max(20px, 1.5vw);

        svg {
          font-size: max(18px, 1.2vw);
        }

        p {
          font-family: "nunitosemi";
          font-size: max(12px, 0.7vw) !important;
          margin-top: 1px;
        }
      }
    }
  }

  .npr-bottom {
    p {
      flex: 1;
      color: var(--main-color);
      font-size: max(13px, 0.75vw);
    }

    #pr-next {
      width: max(100px, 5.6vw);
    }
  }
}
