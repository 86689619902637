.nav-com-box{
    position: absolute;
    top: 180%;
    width: max(300px, 19vw);
    background: #fff;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.15) 0px 7px 29px 0px;
    .nac-comm-inner{
        position: relative;
        #nto-arr{
            position: absolute;
            top: -11px;
            color: #fff;
            left: 47%;
        }
        .nav-comm-top{
            padding: max(15px, 1vw) max(10px, 1vw);
            h4{
                font-family: "nunitosemi";
                font-size: max(12px, 0.75vw);
            }
            .nav-top-tools{
                display: flex;
                align-items: center;
                gap: 5px;
            }

            svg{
                color: #A8AFB7;
                cursor: pointer;
                font-size: max(16px, 1vw);
            }
        }

        .tn-tabs{
            height: max(400px, 35vw);
            overflow-y: auto !important ;
            overflow-x: hidden ;
            &::-webkit-scrollbar {
                width: 5px;
              }
              &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px #a1aec4;
                border-radius: 3px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: #aeb8c9;
                border-radius: 3px;
              }
            .tn-list{
                display: flex;
                align-items: center;
                justify-content: start;
                gap: 10px;
                border-bottom: 1px solid #c2c2c2;
                padding: 0 max(10px, 1vw);
                .react-tabs__tab--selected{
                    color: var(--main-color) !important;
                    border-color: var(--main-color) !important;
                }
                .tn-tab{
                    height: 100%;
                    padding: 15px 10px;
                    font-size: max(10px, 0.65vw);
                    border-bottom: 2px solid transparent;
                    transition: 0.2s;
                }
                #tn-flt{
                    color: #395772;
                    font-size: 14px !important;
                
                }
            }
        }

        .nto-link{
            margin: 20px 0;
            color: var(--main-color);
            font-size: max(12px, 0.7vw);
        }
    }
}

body.dark-mode{
    .nav-com-box{
        background: #2f3943;
        box-shadow: unset;
        border: 1px solid #3f3f3f !important;
        .tn-tab{
            color: #Fff !important;
        }
        #nto-arr{
            color: #2f3943 !important;
        }
        h4{
            color: #fff !important;
        }
        p{
            color: #ddd !important;
        }
        a{
            color: var(--main-color) !important;
        }
        
    }
}