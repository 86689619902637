.newteam-container {
  position: fixed;
  top: 10%;
  left: 35%;
  z-index: 9999;
  background-color: #fff;
  width: max(350px, 37vw);
  color: #000;
  border-radius: max(10px, 0.8vw);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 1300px) {
    width: max(350px, 55vw);
    left: 27%;
  }

  @media (max-width: 800px) {
    width: max(350px, 80vw);
    left: 10%;
  }

  @media (max-width: 550px) {
    width: 90%;
    left: 5%;
  }

  span {
    font-family: "nunitosemi";
    font-size: max(13px, 0.75vw) !important;
  }

  .npr-top {
    border-bottom: 1px solid #d0d0d0;
    gap: 20px;
    justify-content: flex-start;
    padding: 20px max(20px, 1.5vw);

    p {
      font-family: "nunitosemi";
      font-size: max(16px, 1vw) !important;
      cursor: pointer;
      flex: 1;
    }

    svg {
      color: #585858 !important;
      font-size: max(16px, 1vw);
      cursor: pointer;
    }
  }

  .tm-description {
    padding: max(5px, 1vw) max(20px, 1.5vw);

    p {
      font-family: "nunitosemi";
      font-size: max(13px, 0.75vw) !important;
      margin-bottom: 5px;

      span {
        font-family: "nunitoreg";
        color: #6e6b6b;
      }
    }

    textarea {
      border: 1px solid #cecece;
      border-radius: 6px;
      width: 100%;
      height: max(80px, 6.65vw);
      max-height: max(80px, 6.65vw);
      outline: none;
      padding: 10px 0 0 10px;
      font-size: max(12px, 0.75vw);

      &::placeholder {
        font-size: max(12px, 0.75vw);
      }
    }
  }

  .dp-drops {
    padding: 0 max(20px, 1.5vw) max(5px, 1vw) max(20px, 1.5vw);
    gap: 15px;

    .dp-teams {
      width: 100%;
      .dp-inner {
        position: relative;
        margin-top: 10px;
        border: 1px solid #cecece;
        border-radius: 6px;
        width: 100%;
        height: max(40px, 2.85vw);
        padding: 0 max(5px, 0.5vw);

        .dpprj-icon {
          width: max(14px, 0.9vw);
          margin-right: 10px;
          content: url("../../../../assets/Dashboard/Team/users-line.png");
        }

        p {
          font-size: max(12px, 0.7vw);
          flex: 1;
          margin: 0;
          padding: 0;
        }

        svg {
          font-size: 18px;
          color: #5b5b5b;
        }
      }
    }
  }

  .npr-name {
    padding: max(5px, 1vw) max(20px, 1.5vw);

    p {
      font-family: "nunitosemi";
      font-size: max(13px, 0.75vw) !important;
      margin-bottom: 5px;
    }

    .npr-name-area {
      border: 1px solid #cecece;
      border-radius: 6px;
      height: max(40px, 2.75vw);
      justify-content: flex-start;
      padding-left: 15px;

      .nprname-icon {
        width: max(14px, 0.85vw);
        margin-right: 10px;
        content: url("../../../../assets/Dashboard/Overview/Nav/New/edit.png");
      }

      input {
        flex: 1;
        height: 100%;
        border: none;
        outline: none;
        font-size: max(12px, 0.75vw);
        color: #7c858e;
        font-family: "nunitoreg";
        border-radius: 0 6px 6px 0;

        &::placeholder {
          font-size: max(12px, 0.75vw);
          color: #7c858e;
          padding-left: 10px;
        }
      }
    }
  }

  #t222-tx {
    margin: max(5px, 1vw) max(20px, 1.5vw);
    font-size: max(12px, 0.7vw);
  }

  .tm-emails {
    padding: max(5px, 1vw) max(20px, 1.5vw);
    .tm-em-top {
      margin-bottom: max(5px, 0.5vw);
      p {
        font-size: max(13px, 0.75vw);
        font-family: "nunitosemi";
      }
      #skip {
        font-size: max(13px, 0.75vw);
        color: var(--main-color);
        cursor: pointer;
      }
    }

    .tm-em-area {
      border: 1px solid #d4d4d4;
      border-radius: max(10px, 0.75vw);
      padding: 10px;
      display: flex;
      align-items: start;
      gap: 15px;
      #tm-ic {
        font-size: max(14px, 1vw);
        color: #90a2b4;
      }
      textarea {
        flex: 1;
        height: max(80px, 6vw);
        border: none;
        outline: none;
        &::placeholder {
          color: #7c858e;
          font-size: 12px;
        }
      }
    }
  }

  .npr-bottom {
    padding: max(5px, 1vw) max(20px, 1.5vw);
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 10px;

    p {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
      flex: 1;
      color: var(--main-color);
      font-size: max(13px, 0.75vw);
      .magic {
        width: max(14px, 0.9vw);
        content: url("../../../../assets/Dashboard/Notification/magic-wand.png");
      }

      #linkk {
        font-size: max(14px, 0.9vw);
      }
    }
    button {
      font-size: 13px;
      border: none;
      background: none;
    }

    #pr-next {
      background-color: #4280eb;
      width: max(50px, 3.6vw);
      height: max(30px, 2vw);
      border-radius: 6px;
      color: #fff;
    }

    #pr-nextt {
      background-color: #4280eb;
      width: max(90px, 5.6vw);
      height: max(30px, 2vw);
      border-radius: 6px;
      color: #fff;
    }
  }
}
