*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}
@font-face {
  font-family: "nunitobold";
  src: url("./fonts/NunitoSans-Bold.woff");
  font-display: swap;
}
@font-face {
  font-family: "nunitoextrabold";
  src: url("./fonts/NunitoSans-ExtraBold.woff");
  font-display: swap;
}
@font-face {
  font-family: "nunitoextralight";
  src: url("./fonts/NunitoSans-ExtraLight.woff");
  font-display: swap;
}
@font-face {
  font-family: "nunitolight";
  src: url("./fonts/NunitoSans-Light.woff");
  font-display: swap;
}
@font-face {
  font-family: "nunitoreg";
  src: url("./fonts/NunitoSans-Regular.woff");
  font-display: swap;
}
@font-face {
  font-family: "nunitosemi";
  src: url("./fonts/NunitoSans-SemiBold.woff");
  font-display: swap;
}
.shadow {
  box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
}
html {
  height: 100%;
}
p {
  font-weight: 500 !important;
  line-height: 1.7;
}
body::-webkit-scrollbar {
  width: 10px;
  @media (max-width: 1475px) {
    width: 9px;
  }
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

body::-webkit-scrollbar-track {
  background: #e2e2e2;
  border-radius: 1px;
}
body::-webkit-scrollbar-thumb {
  background-color: #474c55;
  border-radius: 1px;
}

body {
  width: 100%;
  height: 100%;
  font-family: "Quicksand", sans-serif;
}

.rec-swipable {
  align-items: stretch;
}
.rec-carousel-item-visible {
  margin: 0;
  display: flex;
}
button {
  cursor: pointer;
}
input {
  outline: none !important;
}
.hover-button {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: fit-content;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #4280eb;
  color: #fff;
  font-size: max(15px, 0.95vw);
  padding: max(10px, 0.75vw) max(25px, 2vw);
  border-radius: max(22px, 2vw);
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hover-button:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2d74ee;
  border-radius: max(22px, 2vw);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hover-button:hover:before,
.hover-button:focus:before,
.hover-button:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-text-fill-color: #000000 !important;
}

body.dark-mode {
  color: #fff;
  background: #080624;
}
:root {
  --main-color: #4280eb;
  --dark-border: #dcdcdc;
  --dark-bg: #fff;
  --light-text: #777;
  --dark-text: #404040;
}
body.dark-mode {
  --main-color: #4280eb;
  --dark-border: #1b173c;
  --dark-bg: #1b173c;
  --dark-text: #ddd;
  --light-text: #bbb;
}
