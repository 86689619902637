.pd-box {
  position: absolute;
  top: 110%;
  right: 5%;
  width: 90%;
  border-radius: 10px;
  padding: 10px 0;
  z-index: 100;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  .pd-items {
    display: flex;
    flex-direction: column;
    p {
      cursor: pointer;
      padding: 5px 10px 5px 20px !important;
      padding-left: 5px;
      font-size: 12px;
      color: #000;
      &:hover {
        background-color: rgb(71, 157, 255);
        color: #fff !important;
      }
    }
  }
}


body.dark-mode{
  .pd-box{
    background: #27313B !important;
    box-shadow: unset;
    border: 1px solid #ddd;
   .pd-items{
    p{
      color: #fff !important;
    }
   }
  }
}